import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import styles from './styles.module.css';

import cityStore from 'stores/city';
import PageHeader from 'components/PageHeader';
import { getScooters, getScooterStats } from 'api/transport';
import Table, {
  Dot,
  TableCell,
  TableHeadCell,
  TableRow,
} from 'components/Table';
import { DEFAULT_PAGINATION } from 'constants/api';
import Checkbox from 'components/Checkbox';
import Action from 'components/Action';
import notify from 'utils/notifications';
import { ACTIONS, BATTERY_LEVEL_MAP } from 'constants/scooter';
import Icon from 'components/Icon';
import Map from 'components/Map';
import { CITIES } from 'constants/map';
import ScooterMarker from 'components/Map/components/ScooterMarker';
import SideBarInfo from 'components/SideBarInfo';
import TransportSideBar from './components/SideBar';

type Id = Scooter['id'];

const TransportPage = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState<Scooter[]>([]);
  const [stats, setStats] = useState<ScooterStatsResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedLoading, setSelectedLoading] = useState(false);
  const [selected, setSelected] = useState<Id[]>([]);
  const [selectedScooter, setSelectedScooter] = useState<Scooter | null>(null);
  const [order, setOrder] = useState<ScooterListFilters['ordering']>('id');
  const [pagination, setPagination] = useState<Pagination>({
    ...DEFAULT_PAGINATION,
  });

  const { selectedCity } = cityStore;

  const filters = useMemo(() => {
    if (!selectedCity) return null;

    return {
      city_id: selectedCity,
      search,
      page: pagination.page,
      limit: pagination.limit,
      ordering: order,
    };
  }, [selectedCity, search, pagination.page, pagination.limit, order]);

  useEffect(() => {
    if (filters) {
      fetchScooterStats();
      fetchScooters(filters);
    }
  }, [filters]);

  const fetchScooterStats = async () => {
    const { data, success } = await getScooterStats();

    if (success) {
      setStats(data);
    }
  };

  const fetchScooters = async (filters: ScooterListFilters) => {
    setSelected([]);
    setLoading(true);

    const { data: res, success } = await getScooters(filters);

    setLoading(false);

    if (success) {
      setPagination({
        ...pagination,
        count: res.count,
        next: !!res.next,
        previous: !!res.previous,
      });

      setData(res.results);
    } else {
      setData([]);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ ...DEFAULT_PAGINATION });
    setData([]);
  };

  const handleRowClick = (id: Id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelected([]);
    } else {
      setSelected(data.map(({ id }) => id));
    }
  };

  const orderClick = (field: keyof Scooter) => {
    setPagination({ ...DEFAULT_PAGINATION });

    if (field === order) {
      setOrder(`-${field}`);
    } else {
      setOrder(field);
    }
  };

  const handleActionClick =
    (action: keyof typeof ACTIONS, ids?: Id[]) => async () => {
      setSelectedLoading(true);

      const _selected = ids || selected;

      const _promise = await Promise.all(
        _selected.map((id) => ACTIONS[action](id)),
      );

      setSelectedLoading(false);

      const success = _promise.filter(({ success }) => success);
      const length = _promise.length;
      const successLength = success.length;
      const failedLength = length - successLength;

      if (successLength) {
        notify(`Sucess: ${successLength}/${length}.`, 'success');

        if (action === 'work' || action === 'outWork') {
          const newData = data.map((s) => {
            const scooterToUpdate = success.find((res) => {
              const _data = res.data as Partial<Scooter>;

              return s.id === _data.id;
            }) as ApiResponse<Partial<Scooter>>;

            return scooterToUpdate
              ? { ...s, ...scooterToUpdate.data }
              : { ...s };
          });

          setData(newData);
        }
      }

      if (failedLength) {
        notify(`Failed: ${failedLength}/${length}.`);
      }
    };

  const handleClickRow = useCallback(
    (id: Id) => {
      const isSelected = selected.includes(id);

      setSelected((prev) =>
        prev.includes(id) ? prev.filter((s) => s !== id) : [...prev, id],
      );

      const zoneRow = document.getElementById(`scooter-${id}`);

      if (zoneRow && !isSelected) {
        zoneRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    [selected],
  );

  const handleClickScooter = useCallback(
    (id: Id) => {
      const scooter = data.find((s) => s.id === id);

      if (scooter) {
        setSelectedScooter(scooter);
      }
    },
    [data],
  );

  const selectedAll = !!data.length && selected.length === data.length;
  const actionsDisabled = !selected.length;

  return (
    <div className={styles.container}>
      <PageHeader
        title="Transport"
        onSearch={handleSearch}
        searchDisabled={loading}
        capitilize
        stats={getStats(stats)}
        actions={
          <>
            <Action
              disabled={actionsDisabled}
              text="Put in work"
              icon="put-in"
              onClick={handleActionClick('work')}
            />

            <Action
              disabled={actionsDisabled}
              text="Put out work"
              icon="put-out"
              onClick={handleActionClick('outWork')}
            />

            <Action
              disabled={actionsDisabled}
              text="Unlock"
              icon="lock"
              onClick={handleActionClick('unlock')}
            />

            <Action
              disabled={actionsDisabled}
              text="Lock"
              icon="lock-closed"
              onClick={handleActionClick('lock')}
            />

            <Action
              disabled={actionsDisabled}
              text="Open battery"
              icon="open"
              onClick={handleActionClick('battery')}
            />

            <Action
              disabled={actionsDisabled}
              text="Restart"
              icon="reload"
              onClick={handleActionClick('reload')}
            />

            <Action
              disabled={actionsDisabled}
              text="Alarm"
              icon="bell"
              onClick={handleActionClick('signal')}
            />

            <Action
              disabled={actionsDisabled}
              text="Update location"
              icon="map"
              onClick={handleActionClick('location')}
            />

            <Action
              disabled={actionsDisabled}
              text="Warehouse"
              icon="house"
              onClick={handleActionClick('wearHouse')}
            />
          </>
        }
      />

      <div className={styles.content}>
        <TransportSideBar
          onActionClick={handleActionClick}
          selectedScooter={selectedScooter}
          onClose={() => setSelectedScooter(null)}
        />

        <Table
          pagination={pagination}
          loading={loading}
          onPaginationChange={(data: Pagination) =>
            setPagination({ ...pagination, ...data })
          }
          header={
            <TableRow>
              <TableHeadCell onClick={handleSelectAll}>
                <Checkbox checked={selectedAll} />
              </TableHeadCell>

              {HEADER_CONFIG.map(({ title, field, center }) => {
                const isOrdering = order.includes(field)
                  ? order.includes('-')
                    ? 'asc'
                    : 'desc'
                  : false;

                return (
                  <TableHeadCell
                    key={field}
                    center={center}
                    order={isOrdering}
                    onClick={() => orderClick(field)}
                  >
                    {title}
                  </TableHeadCell>
                );
              })}
            </TableRow>
          }
        >
          {data.map(
            ({
              id,
              qr_code,
              priority_status,
              battery,
              work_status,
              last_trip_status,
              last_user = '',
              locked,
              connection_status,
              last_activity
            }) => (
              <TableRow
                key={id}
                id={`scooter-${id}`}
                disabled={selectedLoading && selected.includes(id)}
                selected={selected.includes(id)}
                onClick={() => handleRowClick(id)}
              >
                <TableCell>
                  <Checkbox checked={selected.includes(id)} />
                </TableCell>

                <TableCell>
                  <div
                    className={cx(styles.priority, {
                      [styles.high]: priority_status === 'High',
                      [styles.low]: priority_status === 'Low',
                    })}
                  >
                    {priority_status}
                  </div>
                </TableCell>

                <TableCell
                  color="orange"
                  onClick={() => handleClickScooter(id)}
                >
                  {qr_code}
                </TableCell>

                <TableCell
                  className={cx({
                    [styles.redColor]:
                      battery && battery <= BATTERY_LEVEL_MAP.EMERGENCY,
                  })}
                >
                  {battery ? `${battery}%` : '-'}
                </TableCell>

                <TableCell>
                  {last_activity
                    ? last_activity
                    : <>
                      <Dot color={getColorByStatus(last_trip_status)} />

                      {last_trip_status ? last_trip_status : 'Unknown'}
                    </>
                  }

                </TableCell>

                <TableCell>
                  <Dot
                    color={
                      connection_status === 'Good GPS'
                        ? 'green'
                        : connection_status === 'No GPS'
                          ? 'red'
                          : 'black'
                    }
                  />

                  {connection_status}
                </TableCell>

                <TableCell color="orange">
                  {last_user ? `ID ${last_user}` : '-'}
                </TableCell>

                <TableCell center>
                  <Dot color={work_status === 'In work' ? 'green' : 'black'} />
                </TableCell>

                <TableCell center>
                  <Dot
                    color={
                      locked === true
                        ? 'green'
                        : locked === false
                          ? 'red'
                          : 'black'
                    }
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </Table>

        {selectedCity && (
          <Map
            className={styles.map}
            maxZoom={20}
            defaultCenter={CITIES[selectedCity]}
          >
            {data.map(
              ({ id, battery, geo_point }) =>
                !!geo_point && (
                  <ScooterMarker
                    key={id}
                    selected={selected.includes(id)}
                    onClick={() => handleClickRow(id)}
                    battery={battery}
                    lat={geo_point?.latitude}
                    lng={geo_point?.longitude}
                  />
                ),
            )}
          </Map>
        )}
      </div>
    </div>
  );
};

const getStats = (stats: ScooterStatsResponse | null) => {
  if (!stats) return undefined;

  const {
    work_status_stats = [],
    trip_status_stats = [],
    work_status_total = 0,
    connection_status_stats = [],
  } = stats;

  return [
    [
      ...work_status_stats.map(({ work_status: text, total: count }) => {
        let color: Colors = 'blue';

        if (text === 'In work') color = 'green';
        if (text === 'Out of work') color = 'black';
        if (text === 'No connection') color = 'red';

        return { text, count, color };
      }),
      {
        text: 'Total',
        count: work_status_total,
        color: 'orange' as Colors,
      },
    ],

    trip_status_stats.map(({ trips_status: text, total: count }) => {
      let color: Colors = 'red';

      if (text === 'Started') color = 'green';
      if (text === 'Paused') color = 'gray';
      if (text === 'Reserved') color = 'blue';
      if (text === 'Finished') color = 'orange';

      return { text, count, color };
    }),

    connection_status_stats.map(({ connection_status: text, total: count }) => {
      let color: Colors = 'red';

      if (text === 'Good GPS') color = 'green';
      if (text === 'No GPS') color = 'gray';
      if (text === 'No connection') color = 'black';

      return { text, color, count };
    }),
  ];
};

const getColorByStatus = (status: Scooter['last_trip_status']) => {
  if (status === 'Finished') return 'green';
  if (status === 'Reserved') return 'blue';
  if (status === 'Paused') return 'orange';

  return 'black';
};

const HEADER_CONFIG = [
  { title: 'Priority', field: 'priority_status', center: false },
  { title: 'Scooter', field: 'id', center: false },
  { title: 'Battery', field: 'battery', center: false },
  { title: 'Last trip', field: 'last_trip_status', center: false },
  { title: 'Connection', field: 'connection_status', center: false },
  { title: 'User', field: 'last_user', center: false },
  { title: <Icon name="charge" />, field: 'work_status', center: true },
  { title: <Icon name="lock" />, field: 'locked', center: true },
] as const;

export default observer(TransportPage);
