import { memo, ReactNode } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import TablePagination from './Pagination';

import Loader from 'components/Loader';
import Icon from 'components/Icon';

type Props = {
  header: ReactNode;
  children?: ReactNode;
  loading?: boolean;
  pagination?: Pagination;
  onPaginationChange?: (page: Pagination) => void;
  className?: string;
};

const Table = ({
  header,
  children,
  loading,
  pagination,
  onPaginationChange,
  className,
}: Props) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>{header}</thead>

            {!loading && <tbody>{children}</tbody>}
          </table>

          {loading && (
            <div className={styles.loader}>
              <Loader />
            </div>
          )}
        </div>

        {pagination && onPaginationChange && (
          <TablePagination
            loading={loading}
            onPaginationChange={onPaginationChange}
            pagination={pagination}
          />
        )}
      </div>
    </div>
  );
};

export const TableRow = ({
  id,
  children,
  className,
  onClick,
  disabled,
  selected,
}: {
  id?: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <tr
      id={id}
      onClick={handleClick}
      className={cx(styles.row, className, {
        [styles.disabled]: disabled,
        [styles.selected]: selected,
        [styles.clickable]: !!onClick,
      })}
    >
      {children}
    </tr>
  );
};

export const TableCell = memo(
  ({
    children,
    className,
    center,
    width,
    color,
    onClick,
  }: {
    children: ReactNode;
    className?: string;
    center?: boolean;
    width?: number;
    color?: Colors;
    onClick?: () => void;
  }) => {
    const handleClick = (e: React.MouseEvent) => {
      if (onClick) {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }
    };

    return (
      <td
        width={width}
        onClick={handleClick}
        className={cx(
          styles.cell,
          { [styles.center]: center, [styles.orange]: color === 'orange' },
          className,
        )}
      >
        {children}
      </td>
    );
  },
);

export const TableHeadCell = memo(
  ({
    children,
    className,
    onClick,
    center,
    order,
    width,
  }: {
    children: ReactNode;
    className?: string;
    onClick?: () => void;
    center?: boolean;
    order?: 'asc' | 'desc' | false;
    width?: number;
  }) => {
    const sortIcon =
      order === false ? 'sort' : order === 'asc' ? 'sort-up' : 'sort-down';

    return (
      <th
        onClick={onClick}
        className={cx(styles.cell, { [styles.center]: center }, className)}
        style={{ width }}
      >
        {children}

        {order !== undefined && (
          <span
            className={cx(styles.sort, { [styles.active]: order !== false })}
          >
            <Icon name={sortIcon} />
          </span>
        )}
      </th>
    );
  },
);

export const Dot = ({
  color = 'green',
  noMargin = false,
}: {
  color: 'green' | 'blue' | 'orange' | 'black' | 'red';
  noMargin?: boolean;
}) => (
  <div
    className={cx(styles.dot, styles[color], {
      [styles.noMargin]: !!noMargin,
    })}
  />
);

export default Table;
